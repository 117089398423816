.App {
    text-align: center;
    min-height: 100vh;
}

.Light {
    background-color: #c3c7c0;
    color: rgb(39, 39, 39);
}

.Dark {
    background-color: #2b3428;
    color: rgb(225, 235, 225);
}

.Title-Image {
    max-width: calc(10px + 1.75vmax);
    margin-top: -6px;
    margin-left: 20px;
}

a {
    color: inherit;
    text-decoration: inherit;
}

a:hover {
    color: inherit;
    text-decoration: underline;
}

.Version {
    margin-top: 20px;
    font-size: calc(8px + 0.5vmax);
    opacity: 50%;
}

@media (min-width: 550px) {
    .Popover {
        max-width: 500px;
    }
}

.NavBar {
    font-size: calc(10px + 0.5vmax);
}

.MenuDrawer {
    background-color: #bdc7b6;
}

@media (max-width: 991px) {
    .MenuDrawerItem {
        padding-top: 1em;
    }
}

.Achievements-Lottie {
    width: calc(100px + 5vmin);
    display: inline-block;
}

.Alert {
    font-size: calc(10px + 1vmin);
}

.Card:hover {
    cursor: pointer;
}

.Card-Icon-Full {
    filter: invert(0%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.Card-Icon-Almost {
    filter: invert(0%) sepia(0%) saturate(50%) hue-rotate(0deg) brightness(90%) contrast(80%);
}

.Card-Icon-Half {
    filter: invert(0%) sepia(0%) saturate(40%) hue-rotate(0deg) brightness(150%) contrast(40%);
}

.Card-Icon-Threshold {
    filter: invert(0%) sepia(0%) saturate(15%) hue-rotate(0deg) brightness(300%) contrast(50%);
}

.Card-Icon-Silhouette {
    filter: invert(0%) sepia(0%) saturate(5%) hue-rotate(0deg) brightness(300%) contrast(70%);
}

.Card-Icon-Fail {
    filter: invert(60%) sepia(65%) saturate(210%) hue-rotate(340deg) brightness(95%) contrast(120%);
}

.Card-Title {
    font-size: calc(12px + 1.2vmin);
    font-weight: bold;
}

.Card-Text {
    font-size: calc(8px + 1vmin);
}

.Card-Popup-Title {
    font-size: calc(11px + 1vmin);
    font-weight: bold;
}

.Card-Popup-Text {
    font-size: calc(9px + 1vmin);
}

.Image-iNat {
    max-width: 150px;
}

.Image-MyWild {
    max-width: 80px;
    pointer-events: none;
}

.MyWild-Small-Logo {
    max-width: 30px;
    margin-right: 10px;
}

@media (prefers-reduced-motion: no-preference) {
    .Image-MyWild {
        animation: App-logo-spin infinite 40s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.Shine {
    background-image: linear-gradient(-225deg,
            #13610c 0%,
            #50b30e 29%,
            #28c913 67%,
            #13610c 100%);
    background-size: 200% auto;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s linear infinite;
    font-size: calc(10x + 1vmin);
}

@keyframes shine {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

.Card-AnimateBG {
    background-size: 300% 300%;
    background-image: linear-gradient(-45deg,
            rgb(255, 255, 255) 0%,
            rgb(230, 255, 238) 35%,
            rgb(233, 255, 235) 50%,
            rgb(230, 255, 238) 65%,
            rgb(255, 255, 255) 100%);
    animation: AnimateCardBG 10s ease infinite;
}

@keyframes AnimateCardBG {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

.Card-AnimateExpertBG {
    background-size: 300% 300%;
    background-image: linear-gradient(-15deg,
            rgb(250, 255, 250) 0%,
            rgb(232, 255, 230) 35%,
            rgb(225, 255, 235) 50%,
            rgb(219, 255, 209) 65%,
            rgb(250, 255, 250) 100%);
    animation: AnimateExpertCardBG 7s ease infinite;
}

@keyframes AnimateExpertCardBG {
    0% {
        background-position: 0% 60%
    }

    50% {
        background-position: 100% 40%
    }

    100% {
        background-position: 0% 60%
    }
}